import React, { Component, useEffect } from 'react';
import { Link, NavLink, useNavigate  } from 'react-router-dom';
import { store }  from '../../store/index';
//importo immagine
import logo from '../../assets/LOGO123Piazza.png';
import panino from '../../assets/panino.png';
import coppa from '../../assets/coppa.png';
import ingranaggi from '../../assets/ingranaggi.png';
import mappa from '../../assets/mappa.png';
import './style.css';
import loading from '../../assets/loading.gif';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

let authToken = localStorage.getItem("token");
let exitButton = () => {
  console.log("In Header.js exitButton VERIFICA authToken %o", authToken);
  if(authToken){

    console.log("In Header.js exitButton authToken %o", authToken);
    return (
       <button className="btn btn-outline-success" onClick={Esci}>Esci</button>
    )
  } else {
/*
    return (
       <button className="btn btn-outline-success"  onClick={Esci}>Login</button>
    )
*/    
  }
}

function seAutenticato  (label)  {
  if(authToken){
    return (
      <>
        <NavLink to="/team"><img src={coppa} alt="" className="" /> {label.teams}</NavLink>
        <NavLink to="/coupons"><img src={coppa} alt="" className="" />{label.coupons}</NavLink>
        <NavLink to="/giochi"><img src={coppa} alt="" className="" />{label.giochi}</NavLink>
      </>
    )
  } else {
    return (
      <>
        <NavLink to="/login"><img src={coppa} alt="" className="" />Login</NavLink>
      </>
    )

  }
}



  


const Esci = () => {

  console.log("In Header.js esci");
  //remove all from local storage

  localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("email");
  localStorage.removeItem("eta");
  localStorage.removeItem("coupon");
  localStorage.removeItem("id_utente");
  localStorage.removeItem("giocatore");

  store.dispatch({ type: 'SET_TOKEN', payload: null });
  store.dispatch({ type: 'SET_USER', payload: null });
  store.dispatch({ type: 'SET_EMAIL', payload: null });



  window.location.replace("/");


}

function Header1 (props) {
  
    //aggiungi un click per nascondere il menu
    document.addEventListener('click', function(e) {
      if (e.target.id !== 'navbarCollapse') {
        document.getElementById('navbarCollapse').classList.remove('show');
      }
      //
    });
    //controllo se la finestra di gioco è aperta la faccio chiudere gestendo un controllo presente in src\App.js 
    const forzaChiusuraGiochi = () => {
      console.log("In Header.js forzaChiusuraGiochi");
      localStorage.setItem("closeGameWindowCommand", 1);
    }


    return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light paddingBottom">
        <span id="loadingIndicator"><img src={loading} /></span>
        <div className="container-fluid">
          <Link className="navbar-brand logo" to="/"><img src={logo} /></Link>
          <button className="navbar-toggler panino" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <img src={panino} alt="panino" className="panino" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0" onClick={forzaChiusuraGiochi}>
              <NavLink to="/">Home {props.label.currentLanguage}</NavLink>
                { seAutenticato(props.label) }
              <NavLink to="/riscatta"><img src={coppa} alt="" className="" />{props.label.riscattaCoupon}</NavLink>         
              <NavLink to="/areariservata"><img src={ingranaggi} alt="" className="" />{props.label.area}</NavLink>
            </ul>
           { exitButton ()}
           
          </div>
        </div>
      </nav> 
    </header>
    )

}

function Header() {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const label = {
    teams: t('Teams'),
    coupons: t('I miei coupon'),
    giochi: t('Giochi'),
    login: t('Login'),
    riscattaCoupon: t('Riscatta Coupon'),
    area: t('Area Riservata'),
    currentLanguage: currentLanguage
  };

 
  
  return (
    <>  
      <Header1  label={label}  />
    </>
  );
}

export default Header;