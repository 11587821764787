import React from "react";
import "./style.css";
import { useEffect } from "react";

var g_id = "";
var popupText = "";

// Metodo nel genitore che viene eseguito dall'iframe
function metodoNelGenitore(dati) {
  //    window.location.reload();

  window.openModal1("Questo è il titolo", "Questo è il messaggio");
  // Esegui l'azione desiderata nel genitore utilizzando i dati ricevuti dall'iframe
}

function iWin(g_id) {
  //  setto la variabile di sessione youWin
  localStorage.setItem("youWin", 1);
  setTimeout(function () {
    localStorage.setItem("youWin", 1);
  }, 1000);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({ g_id: g_id }),
  };
  fetch(window.apiUrl + "/iWin", requestOptions)
    .then((response) => {
      localStorage.setItem("youWin", 1);
    })
    .then();
}

const MyIframe = (props) => {
  console.log("MyIframe %o", props);

  /*
  Per aggiungere un valore a props bisogna partire da 
  - src\components\Elements\ListaGiochi.js -> change(item.url, item.id_hash, item.popup, item.id_gioco)
  - src\App.js ->                               aggiungere una voce a "state" es: this.setState({giocoId: giocoId});
  - src\App.js ->                              {this.state.showGameBoxVar ? <MyIframe  ... occorre aggiungere il nuovo parametro come attributo di MyIframe
  */

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("closeGameWindowCommand") == 1) {
        localStorage.setItem("closeGameWindowCommand", 0);
        hideGameBox();
      }
    }, 500);
    // Pulisci l'intervallo quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [shown, setShown] = React.useState(true);

  popupText = props.popupText;
  console.log("popupText %o", popupText);
  g_id = props.g_id;

  const reloadGame = () => {
    setShown(!shown);
  };
  const hideGameBox = () => {
    props.hide();
    window.freeWindowBox();
  };

  return (
    <div className="boxGioco" id="gameBox">
      {shown ? <VideoModal src={props.url2Open} /> : null}
    </div>
  );
};

const VideoModal = (props) => {
  return (
    <>
      <div className="iframeGioco" id="iframeGioco">
        <iframe title={props.src} allowFullScreen src={props.src} />
      </div>
    </>
  );
};

export default MyIframe;

function blockWindowBox() {
  document.body.style.overflow = "hidden";
  //document.getElementById('your_iframes_id').scrolling = 'no';
}

function freeWindowBox() {
  document.body.style.overflow = "auto";
  //document.getElementById('your_iframes_id').scrolling = 'yes';
}

// Aggiungi un listener per ascoltare i messaggi dall'iframe
window.addEventListener("message", function (event) {
  var messaggio = event.data;
  var metodoDaEseguire = messaggio.metodo;
  var dati = messaggio.dati;

  if (metodoDaEseguire === "reload") {
    metodoNelGenitore(dati);
  } else if (metodoDaEseguire === "messageWin") {
    var popupTitolo = "Complimenti!";
    var continua = "Continua";
    if(dati.titolo){
      popupTitolo = dati.titolo;
    }
    if(dati.continua){
      continua = dati.continua;
    }

    window.openModal1(
      popupTitolo,
      "" +
        popupText +
        '<br><br><button onClick="window.nascondiGameBox(); " id="nascondiGameBox">'+continua+'</button>'
    );
    iWin(g_id);
  } else if (metodoDaEseguire === "messageLose") {
    window.openModal1("Peccato!", "Hai perso il gioco!");
  } else if (metodoDaEseguire === "loadingOff") {
    window.loading(false, "metodoDaEseguire === 'loadingOff'");
  } else if (metodoDaEseguire === "closeGameWindowCommand") {
    localStorage.setItem("closeGameWindowCommand", 1);
    setTimeout(function () {
      localStorage.setItem("closeGameWindowCommand", 1);
    }, 1000);
  }
});
