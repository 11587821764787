import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

//id 

i18n.use(LanguageDetector).use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "PRONTI AD ESPLORARE": "READY TO EXPLORE",
          "Welcome to React": "En version",
          "RISCATTA IL COUPON": "REDEEM THE COUPON",
          "Tipo di giocatore": "Player type",
          "Famiglia":"Family",
          "Scuola":"Scool",
          "Riscatta Coupon":"Redeem the coupon",
          "Login":"Login",
          "Esci":"Exit",
          "Area Riservata":"Reserved area",
          "Login con coupon":"Login with coupon",
          "Login credenziali":"Login with credentials",
          "Oppure":"Or",
          "opzionale":"optional",
          "Sei già loggato come":"You are already logged in as",
          "Conferma Password":"Confirm Password",
          "Trattamento dei dati":"Personal data processing",
          "leggi come gestiamo i tuoi dati":"Read how we manage your data",
          "Accetto il trattamento dei dati personali.":"I accept the processing of personal data.",
          "Puoi creare 2 team! per personalizzare la difficoltà.":"You can create 2 teams! to customize the difficulty.",
          "Nome del team 1":"Team 1 name",
          "Nome del team 2":"Team 2 name",
          "Difficoltà":"Difficulty",
          "Scegli la difficoltà":"Choose the difficulty",
          "Bassa":"Low",
          "Media":"Medium",
          "Registrati e inizia a giocare":"Register and start playing",
          "Cambio della password":"Change password",
          "Vecchia Password":"Old Password",
          "Nuova Password":"New Password",
          "Cambia Password":"Change Password",
          "Email collegata":"Connected email",
          "Per recuperare la password inserire un codice coupon utilizzato":"To recover the password enter a used coupon code",
          "Recupero Password":"Password recovery",
          "Errore generico recupero password":"Generic error password recovery",
          "Errore recupero password":"Error password recovery",
          "Errore cambio password":"Error password change",
          "Quale Team vuole giocare":"Which Team do you want to play with?",
          "Statistiche":"Statistics",
          "Totale tempo giocato":"Total time played",
          "Ora di riscatto del coupon":"Coupon redemption time",
          "Ora di fine dell'ultimo gioco":"Last game end time",
          "Non ci sono statistiche disponibili!" : "No statistics available!",
          "TUTTE LE TAPPE":"ALL STAGES",
          "Apri Maps per": "Open Maps for",
          "Apri direttamente il gioco": "Open the game directly",
          "Tempo":"Time",
          "Stampa":"Print",
          "Ceramica":"Ceramic",
          "Arte":"Art",
          "TEMPO":"TIME",
          "STAMPA":"PRINT",
          "CERAMICA":"CERAMIC",
          "ARTE":"ART",
          "Chi gioca?":"Who plays?",
          "Nome":"Name",
          "Salva":"Save",
          "Benvenuto":"Welcome",
          "Cambia giocatore":"Change player",
          "I miei coupon":"My coupons",
          "Giochi":"Games",
          "Teams":"Teams",
        }
      },
      it: {
        translation: {
          "PRONTI AD ESPLORARE": "PRONTI AD ESPLORARE?",
          "Welcome to React": "Versione ita", 
          "RISCATTA IL COUPON": "RISCATTA IL COUPON",
          "Tipo di giocatore": "Tipo di giocatore",
          "Famiglia":"Famiglia",
          "Scuola":"Scuola",
          "Riscatta Coupon":"Riscatta Coupon",
          "Login":"Login",
          "Esci":"Esci",
          "Area Riservata":"Area Riservata",
          "Login con coupon":"Login con coupon",
          "Login credenziali":"Login credenziali",
          "Oppure":"Oppure",
          "opzionale":"opzionale",
          "Sei già loggato come":"Sei già loggato come",
          "Conferma Password":"Conferma Password",
          "Trattamento dei dati":"Trattamento dei dati",
          "leggi come gestiamo i tuoi dati":"leggi come gestiamo i tuoi dati",
          "Accetto il trattamento dei dati personali.":"Accetto il trattamento dei dati personali.",
          "Puoi creare 2 team! per personalizzare la difficoltà.":"Puoi creare 2 team! per personalizzare la difficoltà.",
          "Nome del team 1":"Nome del team 1",
          "Nome del team 2":"Nome del team 2",
          "Difficoltà":"Difficoltà",
          "Scegli la difficoltà":"Scegli la difficoltà",
          "Bassa":"Bassa",
          "Media":"Media",
          "Registrati e inizia a giocare":"Registrati e inizia a giocare",
          "Cambio della password":"Cambio della password",
          "Vecchia Password":"Vecchia Password",
          "Nuova Password":"Nuova Password",
          "Cambia Password":"Cambia Password",
          "Email collegata":"Email collegata",
          "Per recuperare la password inserire un codice coupon utilizzato":"Per recuperare la password inserire un codice coupon utilizzato",
          "Recupero Password":"Recupero Password",
          "Errore recupero password":"Errore recupero password",
          "Errore cambio password":"Errore cambio password",
          "Quale Team vuole giocare":"Quale Team vuole giocare",
          "Totale tempo giocato":"Totale tempo giocato",
          "Ora di riscatto del coupon":"Ora di riscatto del coupon",
          "Ora di fine dell'ultimo gioco":"Ora di fine dell'ultimo gioco",
          "Non ci sono statistiche disponibili!" : "Non ci sono statistiche disponibili!",
          "TUTTE LE TAPPE":"TUTTE LE TAPPE",
          "Apri Maps per": "Apri Maps",
          "Apri direttamente il gioco": "Apri il gioco dire",
          "Tempo":"Tempo",
          "Stampa":"Stampa",
          "Ceramica":"Ceramica",
          "Arte":"Arte",
          "TEMPO":"TEMPO",
          "STAMPA":"STAMPA",
          "CERAMICA":"CERAMICA",
          "ARTE":"ARTE",
          "Chi gioca?":"Chi gioca?",
          "Nome":"Nome",
          "Salva":"Salva",
          "Benvenuto":"Benvenuto",
          "Cambia giocatore":"Cambia giocatore",
          "I miei coupon":"I miei coupon",
          "Giochi":"Giochi",
          "Teams":"Teams",
        }
      }
    },
    
   // lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources   

    fallbackLng: "it", // use en if detected lng is not available

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['sessionStorage', 'localStorage',  'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupSessionStorage: 'language',
      lookupLocalStorage: 'language',
      caches: ['localStorage', 'sessionStorage'] 
    },
  });

export default i18n;