import React from 'react';
import './style.css';
import axios from 'axios';
import { store } from '../../store/index';
import { useState, useEffect } from 'react';
import coppa from '../../assets/coppa.png';
import Statistiche from './statistiche';
import { useTranslation } from 'react-i18next';

function Team () {

    const [giocatori, setGiocatori] = useState([]);
    const [nomeTeam, setNomeTeam] = useState('');
    const [scaduto, setScaduto] = useState(false);
    const { t } = useTranslation();
    
     useEffect(() => {
            const bearer_token = `Bearer ${store.getState().savedToken}`;
            const config = {
                headers: {
                    Authorization: bearer_token
                }
            };

            let formData = {coupon: localStorage.getItem('coupon')};
     
            axios.post(window.apiUrl+'/teams', formData, config)
                .then(function (response) {
                    console.log(response.data.data);
                    if ('message' in response.data) {
                        setNomeTeam(response.data.data['nome_team']);

                        if(response.data.data['stato'] === "scaduto"){
                          setScaduto(true);
                        }

                        for (let i = 0; i < response.data.data.giocatori.length; i++) {
                              setGiocatori(giocatori => [...giocatori, response.data.data.giocatori[i]]);
                        }
                        console.log("giocatori intero %o", giocatori);
                    } else {
                        console.log("errore");
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    window.openModal1("Errore", "Si è verificato un errore, riprova più tardi");
                });
     }, []);


     // const [count, setCount] = useState(0);
     // setta in sessione il nome del giocatore selesionato
      const setTeamGiocatore = (nome, id) => {
        localStorage.setItem('nomeTeamGiocatore', nome);
        localStorage.setItem('idTeamGiocatore', id);
        
        window.location.href = '/giochi';
      }

      const Statistiche1 = Statistiche();
    return (
      <div className="homeContainer">
        
        <img src={coppa}  alt="Mascotte Ceramica" className="mascotteCentrale" />
        <h1  className="titoloCentraleGiochi">{nomeTeam}</h1>
        <p>{t("Quale Team vuole giocare")}?</p>
            
              <ul>
              {giocatori.map((teamGiocatore) => (
          <li key={teamGiocatore.id}>
            {scaduto === true ? (
              <button className={localStorage.getItem('idTeamGiocatore') === teamGiocatore.id ? 'selected' : ''} onClick={() =>  window.openModal1("Attenzione", "Il coupon è scaduto")} >
                {teamGiocatore.nome}
              </button>
            ) : (
              <button className={localStorage.getItem('idTeamGiocatore') === teamGiocatore.id ? 'selected' : ''} onClick={() => setTeamGiocatore(teamGiocatore.nome, teamGiocatore.id)}>
                {teamGiocatore.nome}
              </button>
            )}
          </li>
        ))}
      

      </ul>
      <div className="row dati">

        {Statistiche1}

          <ul>
            <li>
              <strong>Email:</strong> {localStorage.getItem('email')}
            </li>
            <li>
              <strong>Coupon:</strong> {localStorage.getItem('coupon')}
            </li> 

          </ul>
      </div>
    </div>
    )

}

export default Team;