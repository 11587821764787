import React, { Component } from 'react';
import '../style.css';


function CoseTesto() {
  return (
    <>
    <div className='textBox homeChoise'> Ita
    <p>Un <b>urban game</b> per le vie di Mondovì Piazza, la parte più alta e storica della città!</p>
    <p>Attraverso <b>enigmi e rompicapi</b>, percorrerai vie e piazze, girando intorno a palazzi e chiese, per scoprire qualcosa in più dei principali punti di interesse storici, artistici e architettonici cittadini. </p>
    <p>4 tematiche, 4 sfaccettature della stessa mascotte, 4 punti cardine di questo tour, ti accompagneranno nell'esplorazione:</p>
    <ul>
      <li>la <span className='textCeramica'><b>ceramica</b></span>, una delle attività di artigianato più peculiari di Mondovì;</li>
      <li>il <span className='textTempo'><b>tempo</b></span>, a simboleggiare il grande orologio visibile da ogni dove;</li>
      <li>la <span className='textStampa'><b>stampa</b></span> perché qui, più che altrove, ha rivestito un ruolo basilare per la conoscenza;</li>
      <li>l’<span className='textArte'><b>arte</b></span> che ci circonda e può portarti in inganno all’infinito.</li>
    </ul>
   </div>
  </>
  );
}

export default CoseTesto;