import React, { Component } from 'react';
import '../style.css';


class Come_funziona extends Component {
  render() {

    const linkHome = () => {
      window.location.replace("/"); 
    }

    return (
      <>
          <div className='textBox homeChoise'>
            <ul>
            <li><b>COMPILA TUTTI I CAMPI:</b> ci servono per garantirti la dovuta privacy e per far sì che nessun altro possa utilizzare il tuo coupon.</li>
            <li><b>RICEVERAI IL COUPON, </b> una volta effettuato il pagamento riceverai il coupon. Inseriscilo nel campo apposito e poi conservalo: ti permetterà di accedere al gioco per le prossime 48 ore! E non solo: presentandolo nelle biglietterie dei 4 musei, potrai entrare a tariffa ridotta.</li>
            <li><b>EFFETTUA L'ACCESSO, </b>scegli fino a due squadre e per ciascuna la difficoltà con cui affrontare le tappe che ti porteranno a girare per Mondovì Piazza. Opta per la difficoltà più adeguata rispetto alla tua età (facile per bambini di 6-8 anni oppure medio per bambini di 9-12 anni).</li>
            <li><b>INIZIA A GIOCARE! </b> Le tappe non sono consequenziali, ma ti consiglieremo sempre quelle più vicine a te tra cui scegliere. Infatti a seguito di ogni tappa, ti verrà richiesto quale direzione vorrai prendere: puoi ritornare sui tuoi passi per tentare di risolvere nuovamente l’enigma o procedere verso una nuova meta;  l’importante è affrontarle tutte nel minor tempo possibile!</li>
            </ul>
          </div>

       </>
    )
  }
}
export default Come_funziona;