import React, { Component } from 'react';
import '../style.css';


class Come_funziona extends Component {
  render() {

    const linkHome = () => {
      window.location.replace("/"); 
    }

    return (
      <>
          <div className='textBox homeChoise'>[Inglese]
            <ul>
            <li><b>CREATE YOUR ACCOUNT:</b> to assure your privacy and to make sure nobody else is using your code.</li>
            <li><b>YOU WILL GET YOUR CODE, </b> once you have paid you will get a code. You can use it for 48 hours! You can also show your coupon to the city museums to get a reduced-price ticket.</li>
            <li><b>LOGIN, </b>create up to two teams and for each one chooses the difficulty level according to the age of the children (easy for 6-8 years or medium for 9-12).</li>
            <li><b>START PLAYING!  </b> The stages are not sequential, but we will always show you the closest ones. After every stop we will ask you where to go next: you can go back and try to solve a previous puzzle or to go find a new one; the only important thing is to solve them all in the shortest time!</li>
            </ul>
          </div>

       </>
    )
  }
}
export default Come_funziona;