import React, { Component } from 'react';
import './style.css';

import TextCos_e_it from '../About/it/cos_e_testo';
import TextCos_e_en from '../About/en/cos_e_testo';
import TextChi_coinvolge_it from '../About/it/chi_coinvolge_testo';
import TextChi_coinvolge_en from '../About/en/chi_coinvolge_testo';
import TextCome_funziona_it from '../About/it/come_funziona_testo';
import TextCome_funziona_en from '../About/en/come_funziona_testo';

//IMMAGINI
import backgroundImage from '../../assets/rifiloBasso.png';
import accedi_it from '../../assets/accedi.png'
import accedi_en from '../../assets/accedi_en.png'
import chi_coinvolge_it from '../../assets/chi_coinvolge.png';
import chi_coinvolge_en from '../../assets/chi_coinvolge_en.png';
import come_funziona_it from '../../assets/come_funziona.png';
import come_funziona_en from '../../assets/come_funziona_en.png';
import cos_e_it from '../../assets/cos_e.png';
import cos_e_en from '../../assets/cos_e_en.png';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';


 function Home() {
  if (!sessionStorage.getItem('language')) {
    sessionStorage.setItem('language', 'it'); 
  }
  const [isOpenCos_e, setIsOpen] = useState(false);
  const [isOpenCome_funziona, setIsOpenCome_funziona] = useState(false);
  const [isOpenChi_coinvolge, setIsOpenChi_coinvolge] = useState(false);

  const { t } = useTranslation();
  // lingua attuale
  const currentLanguage = i18n.language;
  let TextCos_e;
  let TextChi_coinvolge;
  let TextCome_funziona;
  let accedi;
  let chi_coinvolge;
  let come_funziona;
  let cos_e;
  switch (currentLanguage) {
    case 'it':
      TextCos_e = TextCos_e_it;
      TextChi_coinvolge = TextChi_coinvolge_it;
      TextCome_funziona = TextCome_funziona_it;
      accedi = accedi_it;
      chi_coinvolge = chi_coinvolge_it;
      come_funziona = come_funziona_it;
      cos_e = cos_e_it;
      break;
    case 'en':
      TextCos_e = TextCos_e_en;
      TextChi_coinvolge = TextChi_coinvolge_en;
      TextCome_funziona = TextCome_funziona_en;
      accedi = accedi_en;
      chi_coinvolge = chi_coinvolge_en;
      come_funziona = come_funziona_en;
      cos_e = cos_e_en;
      break;
    default:
      TextCos_e = TextCos_e_it;
      TextChi_coinvolge = TextChi_coinvolge_it;
      TextCome_funziona = TextCome_funziona_it;
      accedi = accedi_it;
      chi_coinvolge = chi_coinvolge_it;
      come_funziona = come_funziona_it;
      cos_e = cos_e_it;
  }
  //apri 

  
  function toggle(cosa) {
    setIsOpen((isOpenCos_e) => false);
    setIsOpenCome_funziona((isOpenCome_funziona) => false);
    setIsOpenChi_coinvolge((isOpenChi_coinvolge) => false);


    switch (cosa)
    {
      case "cos_e":
        setIsOpen((isOpenCos_e) => !isOpenCos_e);
        break;
      case "come_funziona":
        setIsOpenCome_funziona((isOpenCome_funziona) => !isOpenCome_funziona);
        break;
      case "chi_coinvolge": 
        setIsOpenChi_coinvolge((isOpenChi_coinvolge) => !isOpenChi_coinvolge);
        break;
      default: 
          alert('Default case');
    }
    
  }
  const changeLanguage = (lng) => {
    document.cookie = "language=" + lng;
    sessionStorage.setItem('language', lng); 
    i18n.changeLanguage(lng);
  }
 


  return (
    <>
    <h1 className='h1Home' style={{ backgroundImage: `url(${backgroundImage})` }}>{t("PRONTI AD ESPLORARE")} ?</h1>
    <div className='homeContainer'>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>


    <div className="navigationButtons">
      <button onClick={() => changeLanguage('it')}>it</button>
      <button onClick={() => changeLanguage('en')}>en</button>
    </div>

    <a onClick={() => toggle('cos_e')}>
      <img src={cos_e}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenCos_e && <TextCos_e />}
    </a>
    <a onClick={() => toggle('come_funziona')}>
      <img src={come_funziona}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenCome_funziona && <TextCome_funziona />}
    </a>
    <a onClick={()=> toggle('chi_coinvolge')}>
      <img src={chi_coinvolge}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenChi_coinvolge && <TextChi_coinvolge />}
    </a>
    {sessionStorage.getItem('token') && (
      <a href="/riscatta">
        <img src={accedi} alt="Mascotte Ceramica" className="sceltaCentrale" />
      </a>
    )}
    {!sessionStorage.getItem('token') && (
      <a href="/login">
        <img src={accedi} alt="Mascotte Ceramica" className="sceltaCentrale" />
      </a>
    )} 
    </div>
    <div className="homeFooter">
      <p>© 2024 - 123 Piazza - Tutti i diritti riservati</p>
      <p><a href='/privacy'>Privacy</a> | <a href='/cookies'>Cookies</a> | <a href='/notelegali'>Note Legali</a></p>
    </div>
    </>
  )
}

export default Home;