import React, { Component } from 'react';
import '../style.css';


function CoseTesto() {
  return (
    <>
    <div className='textBox homeChoise'> 
    <p>This is an <b>urban game </b>that will guide you through the streets of Mondovì Piazza, the oldest and upper part of the town!</p>
    <p> <b>Though puzzles and brain</b> teasers you will discover streets and squares, palaces and churches, to find out something 
      more about the most important historical and artistic points of interest.</p>
    <ul>
      <li>la <span className='textCeramica'><b>ceramic</b></span>, one of the most typical craft productions in Mondovì;</li>
      <li>il <span className='textTempo'><b> time</b></span>, symbolized by the great clock you can see from everywhere in Mondovì;</li>
      <li>la <span className='textStampa'><b>	printing </b></span> because here, more than anywhere else, it was crucial for the spread of knowledge and culture;</li>
      <li>l’<span className='textArte'><b>•	art </b></span> that surrouds and also plays tricks on us.</li>
    </ul>
   </div>
  </>
  );
}

export default CoseTesto;