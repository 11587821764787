import React, { Component } from "react";
import "./style.css";

class NoteLegali extends Component {
  render() {
    return (
      <><br /><br /><br />
        <h1>Note Legali e Termini d'uso</h1>
        <p>
          <b><i>Un URBAN GAME per le vie di Mondovì Piazza, la parte più alta e           storica della città!</i></b>
        </p>
        <p>
          <b>Limitazione delle responsabilità:</b>
          <br />
          - Il servizio è fornito "così com'è" e "come disponibile". 
          <br />
          - Il servizio necessita di una connessione internet per funzionare correttamente.
          <br />
          - Il servizio utilizza la tecnologia standard web e mobile e non può garantire che il servizio soddisfi le esigenze dell'utente o che il servizio sarà ininterrotto, tempestivo, sicuro o privo di errori. 
          <br />
          - Le diemensioni e le caratteristiche del dispositivo mobile possono influenzare il corretto funzionamento del servizio.
          <br />
          - Il titolare non si assume alcuna responsabilità per la cancellazione o mancata memorizzazione di qualsiasi contenuto e altre comunicazioni mantenute o trasmesse tramite il servizio per motivi tecnici non previsti. 
          <br />
          - Il servizio può includere collegamenti a siti web o risorse di terze parti.
          <br />
          - Utilizzare la Web App sul proprio dispositivo mobile prestando attenzione ai pericoli circostanti: traffico, barriere architettoniche, etc.
          <br />
          - Utilizzare la Web App in modo responsabile e consapevole delle normative di sicurezza: non fissare il dispositivo per periodi prolungati, moantenere una distanza adeguata dal dispositivo, mantenere e fare mantenere all'utilizzatore una postura corretta.
          <br />
          - Il titolare del servizio non è responsabile di malfunzionamenti, bug o errori non dipendenti dalla propria volontà.
          <br />
        </p>
        <p><b>Contenuti:</b>
        <br />
          Tutti i diritti sui contenuti grafici, testuali e multimediali sono del Museo della Ceramica di Mondovì</p>
        <p>
          <b>Indirizzo:</b>
          <br />
          Museo della Ceramica di Mondovì
          <br />
          Palazzo Fauzone di Germagnano
          <br />
          Piazza Maggiore 1
          <br />
          12084 Mondovì (CN)
          <br />
          <b>Mail:</b>
          segreteria@museoceramicamondovi.it
          <br />
          <b>Ufficio turistico del Comune di Mondovì</b>: 0174 330358
        </p>
 
      </>
    );
  }
}

export default NoteLegali;
