import React, { Component } from 'react';
import '../style.css';

class About extends Component {
  render() {
    return (
       <>
          <div className='textBox homeChoise'> 
            <ul>
                <li><span className='evidenzaInElenco textMuseoCeramica'><b>•	CERAMICS’ MUSEUM </b></span> You can find not only a display of ceramics, but also multimedia installations and multisensory paths to discover ceramics, ceramists and all the materials used (mud, water and pigments).</li>
                <li><span className='evidenzaInElenco textMuseoStamoa'><b>•	CITY PRINTING MUSEUM </b></span> an immersive journey into books, printing and the place that hosts it. From the invention of movable type to digitalization, not only machineries are shown to understand the revolution of modern communication.</li>
                <li><span className='evidenzaInElenco textTorreBelvedere'><b>•	BELVEDERE TOWER </b></span>It is the symbol of the city and a unique point of view over a wide panorama. A “park of time”.</li>
                <li><span className='evidenzaInElenco textChiesaMissione'><b>CHIESA DELLA MISSIONE</b></span>” A Baroque treasure in Piedmont. An adventure to discover architecture and optical illusion.</li>
            </ul>
            <p>This project has been funded by Fondazione CRC as part of the call “FuoriOrario 2022”.</p>
          </div>
      </>
    )
  }
}

export default About;